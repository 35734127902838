import React from "react"
import classNames from "classnames"
import styles from "./index.module.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEarthAsia } from "@fortawesome/free-solid-svg-icons"
import Link from "next/link"

function getLinks() {
  let links = {
    alluena: "https://alluena.godus.de",
    map: "https://maps.godus.de"
  }

  if (process.env.NODE_ENV === "development") {
    links = {
      ...links,
      alluena: "/alluena",
      map: "/map"
    }
  }

  return links
}

export default function Home() {
  const date = new Date()
  const lastBuildDate = `${date.getFullYear() - 2000}.${date.getMonth() + 1}.${date.getDate()}`

  const links = getLinks()

  return (
    <>
      <div className={classNames(styles["kupferstich-bg"])}>
        <div className={classNames(styles["title"])}>
          <h1>Das Götteroktet der <br />uneitlen Sieben</h1>
        </div>

        <div className={styles["link-container"]}>
          <Link href={links.map} className={styles["link"]}>
            <FontAwesomeIcon icon={faEarthAsia} className="mr-2" />
            Zur Weltkarte
          </Link>
        </div>

      </div>
      <small className={styles.footer}>
        <span><Link href="/impressum">Impressum</Link></span>&nbsp;|&nbsp;<span>v{lastBuildDate}</span>
      </small>
    </>
  )
}